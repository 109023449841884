import store from '../store'

const hasPermission = {
    install (Vue, options) {
        //console.log(options);
        Vue.directive('has', {
            inserted: (el, binding, vnode)=>{
                if(!filterAuthPermission(el, binding, vnode)){
                    el.parentNode && el.parentNode.removeChild(el)
                }

                /*const { value } = binding;
                console.log("value++++++++++++++++: ", value)
                if(value && value === 123){
                    console.log("value++++++++++++++++: 展示")
                }else {
                    el.parentNode && el.parentNode.removeChild(el)
                    console.log("value++++++++++++++++: 隐藏")
                }*/
            }
        });
    }
};
export function filterAuthPermission(el, binding, vnode) {
    const { value } = binding;

    if(!value){
        console.log("按钮控制Value:  ", value)
        return true;
    }
    /*if(!value && value !== null && typeof value !== 'undefined' ){
        throw new Error(`请设置权限标识符`)
    }*/
    let authVal;
    try {
        authVal = Number(value);
    } catch (e) {
        throw new Error(`请设置有效权限标识符`)
    }

    // 获取所有按钮权限信息
    let authList = [];
    if(store.state.auths.length > 0){
        authList = store.state.auths;
    }
    if(authList.length <= 0){
        return false;
    }

    for(let item of authList){
        if(authVal === item.id){
            return true;
        }
    }
    return false;
}
export default hasPermission;