import request from '@/utils/request'

export function login(username, password) {
    return request({
        url: '/user/login',
        method: 'post',
        data: {
            username: username,
            password: password
        }
    })
}

export function config(data) {
    return request({
        url: '/user/config',
        method: 'post',
        data: data
    })
}


export function permList() {
    return request({
        url: '/perm/list',
        method: 'post'
    })
}

export function permAllList() {
    return request({
        url: '/perm/allList',
        method: 'post'
    })
}

export function info() {
    return request({
        url: '/user/info',
        method: 'get'
    })
}
