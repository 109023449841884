import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menus: [],
    auths: [],
    btns: [],
    sysconfig: []
  },
  getters: {
  },
  mutations: {
    SET_MENUS: (state, menus) => {
      state.menus = menus
    },
    SET_SYSCONFIG: (state, sysconfig) => {
      state.sysconfig = sysconfig
    },
    SET_AUTH: (state, auths) => {
        state.auths = auths;
        for(let btn of auths){
          state.btns.push(btn.id);
        }
    },
    RESET_STATE: (state) => {
      Object.assign(state, {
        menus: [],
        auths: [],
        btns: [],
      })
    },
  },
  actions: {
  },
  modules: {
  }
})
