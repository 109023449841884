import router from './router'
import NProgress from 'nprogress'       // Progress 进度条
import 'nprogress/nprogress.css'        // Progress 进度条样式
import { getToken } from '@/utils/auth' // 验权
import store from './store'
import { asyncRouterMap } from '@/router/asyncRouter';
import { permList } from './api/login';

const whiteList = ['/hanger']            // 不重定向白名单
router.beforeEach((to, from, next) => {
    // 修改标题
    document.title = to.meta.title;

    NProgress.start()

    if (getToken()) {
        if (to.path === '/') {
            // next({ path: '/' })
            NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
        } else {
            //  判断全局路由是否存在
            if (store.state.menus.length === 0) {
                //TODO 请求后台动态添加路由
                permList().then(res => {
                    console.log(res);
                    const menuArr = res.data.menuList;
                    const authArr = res.data.authList;
                    let routes = addR(menuArr);
                    routes.forEach(res=>{
                        router.addRoute(res);
                    })
                    //router.addRoutes(routes);
                    store.commit('SET_MENUS', routes);
                    store.commit('SET_AUTH', authArr)
                    next({ ...to, replace: true });
                });
                // router.addRoutes(asyncRouterMap);
            } else {
                next()
            }

            //

        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next('/hanger')
            NProgress.done()
        }
    }
});

function addR(data){
    let res = generateRoutes(data, asyncRouterMap);
    // 404页一定要最后添加
    res.push({
        path: '*',
        name: '404',
        component: () => import('@/views/Error'),
        meta: { title: '404' },
    });
    return res;
}

function generateRoutes(data, arr) {
    let route = [];
    arr.forEach(item => {
        // 判断是否应该添加这个路由
        if (data.find(i => i.name === item.meta.title)) {
            // 判断是否有子路由
            if (item.children) {
                // 有子路由，递归判断子路由是否应该添加
                let children = generateRoutes(data, item.children);
                route.push({
                    ...item,
                    children
                });
            } else {
                // 没有子路由直接添加
                route.push(item);
            }
        }
    });
    return route;
}

router.afterEach(() => {
    NProgress.done() // 结束Progress
})
