import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/login',
        name: '碳资产管理SAAS平台登录',
        meta: {title: '碳资产管理SAAS平台登录'},
        component: () => import('@/views/LoginView')
    },
    {
        path: '/hanger',
        name: '钩子',
        meta: {title: '钩子'},
        component: () => import('@/views/HangerView')
    },
    {
        path: '/',
        redirect: '/home'
    },
]

const router = new VueRouter({
    routes
})

export default router
